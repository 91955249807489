import { API_V2 } from "@/config/api";
import { Donations } from "@/types/models/Donations";
import apiRequest from "./api";

export const routes = {
  donations: `${API_V2}/donations`,
  timeSerie: `${API_V2}/donations/time-serie`,
  export: `${API_V2}/donations/report`,
  receipt: (id: number) => `${API_V2}/donations/${id}/receipt`,
  create: `${API_V2}/donations`,
  createOffline: `${API_V2}/donations/offline`,
  update: (id: number) => `${API_V2}/donations/${id}`,
  delete: (id: number) => `${API_V2}/donations/${id}`,
  annualReport: `${API_V2}/donations/annual-report`,
};

export interface DonationsRequest {
  page: number;
  limit: number;
  sortBy: "createdAt" | "donatedGross" | "donationCount";
  sortDirection: "ASC" | "DESC";
  campaignId?: number;
  donorId?: number;
}

export interface DonationsResponse {
  result: Donations[];
  total: number;
  page: number;
  pages: number;
}

export const downloadDonations = () =>
  apiRequest<any>(routes.export, {
    method: "GET",
  });

export const downloadReceipt = (id: number) =>
  apiRequest<Buffer>(routes.receipt(id), {
    method: "GET",
    responseType: "blob",
  });

export interface CreateOfflineDonation {
  grossAmount: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  company?: string;
  comment?: string;
  createdAt?: Date;
  campaignId?: number;
}
export const createOfflineDonation = (data: CreateOfflineDonation) =>
  apiRequest<Donations>(routes.createOffline, {
    method: "POST",
    data: { ...data },
  });

export const deleteOffline = (id: number) =>
  apiRequest<any>(routes.delete(id), {
    method: "DELETE",
  });

export interface UpdateOfflineDonation {
  amount?: number;
  comment?: string;
}
export const updateOfflineDonation = (
  id: number,
  data: UpdateOfflineDonation
) =>
  apiRequest<Donations>(routes.update(id), {
    method: "POST",
    data,
  });

export interface DownloadAnnualReportRequest
  extends Record<string, string | number> {
  donorId: number;
}

export const downloadAnnualReport = (data: DownloadAnnualReportRequest) =>
  apiRequest<Buffer>(routes.annualReport, {
    method: "GET",
    params: data,
    responseType: "blob",
  });
